/**
 * Provider for marketo
 */
export const MARKETO_PROVIDER = 'marketo';

/**
 * Integration test connection config
 */
export type IntegrationTestConnectionConfig = {
  clickCustomActivityType?: ClickCustomActivityType;
  clientId: string;
  clientSecret: string;
  endpoint: string;
  grantType: string;
  identity: string;
  leadFields: {
    displayName: string;
    restName: string;
  }[];
};

/**
 * Integration test connection response
 */
export type IntegrationTestConnectionResponse = {
  authorisedUser: string;
  clickCustomActivityType?: ClickCustomActivityType;
  pass: boolean;
  tests: {
    description: string;
    pass: boolean;
    warning: boolean;
  }[];
};

/**
 * Show/Hide test connection response
 */
export type DispalayTestConnection = {
  isShown: boolean;
  testConnection?: IntegrationTestConnectionResponse;
};

/**
 * Click custom activity type
 */
export type ClickCustomActivityType = {
  apiName: string;
  enabled: boolean;
  id: string;
  status: string;
  pardotSyncSetting?: PardotSyncSettings;
};

/**
 * Token key
 */
export const TOKEN_KEY = 'CrmToken';

/**
 * Provider for hubspot
 */
export const HUBSPOT_PROVIDER = 'hubspot';

/**
 * Provider for pardot
 */
export const PARDOT_PROVIDER = 'pardot';

/**
 * Token of the hubspot
 */
export type HubSpotGetToken = {
  access_token_not_found?: boolean;
  access_token: string;
  account_id?: string;
  expires_in: number;
  refresh_token: string;
  token_type: string;
};

/**
 * Token of the pardot
 */
export type PardotGetToken = {
  id: string;
  access_token: string;
  refresh_token: string;
  signature: string;
  exp: number;
  scope: string;
  id_token: string;
  instance_url: string;
  token_type: string;
  issued_at: number;
};

/**
 * Response of the pardot account
 */
export type PardotGetAccount = Pick<PardotGetToken, 'exp' | 'token_type'> & {
  user_id: string;
  organization_id: string;
  display_name: string;
  email: string;
  first_name: string;
  last_name: string;
  token: string;
  id: string;
  syncSettings: PardotSyncSettings;
};

export type PardotSyncSettings = {
  defaultBusinessUnitId: string;
  customDomain: string;
  isSandbox: string;
  marketingAppExtensionName?: string;
};

/**
 * Response of the hubspot account
 */
export type HubSpotGetAccount = Pick<HubSpotGetToken, 'expires_in' | 'token_type'> & {
  app_id: number;
  hub_domain: string;
  hub_id: number;
  scope_to_scope_group_pks: number[];
  scopes: string[];
  token: string;
  trial_scope_to_scope_group_pks: []; // TODO: add strict array type once it received in response
  trial_scopes: []; // TODO: add strict array type once it received in response
  user_id: number;
  user: string;
};

/**
 * Selecte from connected crm modal response
 */
export type CrmSelectedLandingPages = Pick<CrmLandingPages, 'id' | 'portal_id' | 'url' | 'title'> & {
  source: string;
  state: string;
};

/**
 * Response of crm manual sync
 */
export type CrmManualSync = {
  campaignsCount: number;
  targetGroupsCount: number;
};

/**
 * Marketo list data object
 */
export type MarketoListDataObj = {
  programName: string;
  workspaceId: number;
  workspaceName: string;
};

/**
 * Marketo lists
 */
export type MarketoLists = Pick<ClickCustomActivityType, 'id'> & {
  createdAt: Date;
  data: MarketoListDataObj;
  imported: boolean;
  name: string;
};

/**
 * Hubspot target groups
 */
export type HubspotTargetGroups = {
  count: number;
  description: string;
  lists: HubSpotLists[];
  provider: string;
  providerTitle: string;
};

/**
 * Marketo target groups
 */
export type MarketoTargetGroups = {
  count: number;
  description: string;
  lists: MarketoLists[];
  provider: string;
  providerTitle: string;
};

/**
 * CRM import target groups response
 */
export type CrmImportTargetGroup = {
  importedContacts: number;
  importedLists: number;
  noEmailContactsCount: number;
  skippedContacts: number;
  skippedList: number;
  skippedOptedOutContactsCount: number;
};

/**
 * Hubspot lists
 */
export type HubSpotLists = {
  createdAt: Date;
  id: string;
  imported: boolean;
  name: string;
  size: number;
  type: string;
};

/**
 * Landing pages response fron connected crms
 */
export type CrmLandingPages = {
  archived: boolean;
  created: Date;
  current_state: string;
  currently_published: boolean;
  featured_image_alt_text: string;
  featured_image: string;
  id: string;
  is_draft: boolean;
  name: string;
  portal_id: number;
  screenshot_preview_url: string;
  title: string;
  url: string;
};

/**
 * Response of lead fields of CRMs
 */
export type GetLeadFields = {
  requestId: string;
  result: LeadFields[];
  success: boolean;
  errors?: {
    code: number;
    message: string;
  }[];
};

/**
 * List of sync settings lead fields
 */
export type LeadFields = {
  dataType: string;
  displayName: string;
  id: number;
  rest: {
    name: string;
  };
};

/**
 * List of marketo workspace
 */
export type MarketoWorkspace = {
  createdAt: Date;
  currencyInfo?: string;
  description: string;
  globalviz: boolean;
  id: number;
  name: string;
  status: string;
  updatedAt: Date;
};

/**
 * List of marketo program
 */
export interface MarketoProgram {
  channel: string;
  createdAt: Date;
  description: string;
  folder: {
    folderName: string;
    type: string;
    value: number;
  };
  id: number;
  name: string;
  status: string;
  type: string;
  updatedAt: Date;
  url: string;
  workspace: string;
}

/**
 * Pardot target groups
 */
export type PardotTargetGroups = {
  count: number;
  description: string;
  lists: PardotLists[];
  provider: string;
  providerTitle: string;
};

/**
 * Pardot lists
 */
export type PardotLists = {
  createdAt: Date;
  id: number;
  imported: boolean;
  name: string;
  type: string;
  size: number;
  data: any;
};
